import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {Box, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import CallEndIcon from '@mui/icons-material/CallEnd';
import VoicemailRoundedIcon from '@mui/icons-material/VoicemailRounded';
import SmartToyRoundedIcon from '@mui/icons-material/SmartToyRounded';
import {CommunicationType, SpartanCommunicationItem} from '../../model/Lead';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {formatDateTime} from '../../utils/DateUtils';
import {FeatureName} from '../../../paths';
import NotificationService, {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';
import CloseIcon from '@mui/icons-material/Close';

interface SpartanCommunicationItemProps {
  communicationItem: SpartanCommunicationItem;
}
const CallCommunicationField = ({communicationItem}: SpartanCommunicationItemProps) => {
  const {t} = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
        <Chip icon={<CallEndIcon />} label={formatDateTime(communicationItem.created_at)} />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Typography variant="h6" noWrap>
          {t('communication.campaign-name')}
        </Typography>
        <Typography variant="body1">{communicationItem?.campaign_name}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Typography variant="h6" noWrap>
          {t('communication.campaign-type')}
        </Typography>
        <Typography variant="body1">{communicationItem?.campaign_type}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.from')}</Typography>
        <Typography variant="body1">{communicationItem?.from_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.to')}</Typography>
        <Typography variant="body1">{communicationItem?.to_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.direction')}</Typography>
        <Typography variant="body1">{communicationItem?.direction}</Typography>
      </Grid>
    </>
  );
};
const SmsCommunicationField = ({communicationItem}: SpartanCommunicationItemProps) => {
  const {t} = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
        <Chip icon={<SmsIcon />} label={formatDateTime(communicationItem.created_at)} />
      </Grid>
      {communicationItem?.campaign_name && (
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h6" noWrap>
            {t('communication.campaign-name')}
          </Typography>
          <Typography variant="body1">{communicationItem?.campaign_name}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Typography variant="h6" noWrap>
          {t('communication.campaign-type')}
        </Typography>
        <Typography variant="body1">{communicationItem?.campaign_type}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.from')}</Typography>
        <Typography variant="body1">{communicationItem?.from_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.to')}</Typography>
        <Typography variant="body1">{communicationItem?.to_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.direction')}</Typography>
        <Typography variant="body1">{communicationItem?.direction}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Typography variant="h6">{t('communication.text')}</Typography>
        <Typography variant="body1">{communicationItem?.sms_text}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6" noWrap>
          {t('communication.sms-status')}
        </Typography>
        <Typography variant="body1">{communicationItem?.sms_status}</Typography>
      </Grid>
    </>
  );
};
const RvmCommunicationField = ({communicationItem}: SpartanCommunicationItemProps) => {
  const {t} = useTranslation();
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
        <Chip icon={<VoicemailRoundedIcon />} label={formatDateTime(communicationItem.created_at)} />
      </Grid>
      {communicationItem?.campaign_name && (
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h6" noWrap>
            {t('communication.campaign-name')}
          </Typography>
          <Typography variant="body1">{communicationItem?.campaign_name}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Typography variant="h6" noWrap>
          {t('communication.campaign-type')}
        </Typography>
        <Typography variant="body1">{communicationItem?.campaign_type}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.from')}</Typography>
        <Typography variant="body1">{communicationItem?.from_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6">{t('communication.to')}</Typography>
        <Typography variant="body1">{communicationItem?.to_number}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1}>
        <Typography variant="h6" noWrap>
          {t('communication.status')}
        </Typography>
        <Typography variant="body1">{communicationItem?.status}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Typography variant="h6">{t('communication.rvm-id')}</Typography>
        <Typography variant="body1">{communicationItem?.rvm_id}</Typography>
      </Grid>
    </>
  );
};
const AICallCommunicationField = ({communicationItem}: SpartanCommunicationItemProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  return (
    <>
      <Grid
        item
        xs={12}
        container
        alignItems="top"
        sx={{
          mt: (theme) => theme.spacing(2),
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
          <Chip icon={<SmartToyRoundedIcon />} label={formatDateTime(communicationItem.created_at)} />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h6" noWrap>
            {t('communication.campaign-name')}
          </Typography>
          <Typography variant="body1">{communicationItem?.campaign_name}</Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Typography variant="h6" noWrap>
            {t('communication.campaign-type')}
          </Typography>
          <Typography variant="body1">{communicationItem?.campaign_type}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1}>
          <Typography variant="h6">{t('communication.from')}</Typography>
          <Typography variant="body1">{communicationItem?.from_number}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1}>
          <Typography variant="h6">{t('communication.to')}</Typography>
          <Typography variant="body1">{communicationItem?.to_number}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1}>
          <Typography variant="h6" noWrap>
            {t('communication.status')}
          </Typography>
          <Typography variant="body1">{communicationItem?.status}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Typography variant="h6">{t('communication.ai-call-id')}</Typography>
          <Typography variant="body1">{communicationItem?.ai_call_id}</Typography>
        </Grid>
      </Grid>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth>
        <DialogTitle>
          <Typography variant="h2">{t('communication.details.headline')}</Typography>
          <IconButton sx={{position: 'absolute', right: 8, top: 8}}>
            <CloseIcon
              onClick={() => {
                setIsModalOpen(false);
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {Object.keys(communicationItem).map((key, index) => {
            return (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={index % 2 ? 'overview-dialog-row-odd' : 'overview-dialog-row-even'}
                  sx={{
                    paddingInline: '25px',
                    paddingBlock: '14px',
                    wordBreak: 'break-word',
                  }}
                >
                  <Typography component="span" sx={{fontWeight: '500'}}>
                    {t(`communication.details.${key}`)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{paddingInline: '25px', paddingBlock: '14px'}}
                  className={index % 2 ? 'overview-dialog-row-odd-light' : 'overview-dialog-row-even-light'}
                >
                  <Typography sx={{whiteSpace: 'pre-wrap'}}>{communicationItem[key].toString()}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};
export function SpartanCommunicationField() {
  const {useAxiosBFF} = useAxiosContext();
  const {t} = useTranslation();
  const {id} = useParams();
  const {setLoading} = useLoading();
  const [communication, setCommunication] = useState<Array<SpartanCommunicationItem> | null>(null);

  const [{error: hasGetLeadCommError, response: getLeadCommResponse, loading: isGetLeadLoading}] = useAxiosBFF<
    ResponseListWrapper<SpartanCommunicationItem>
  >({method: 'GET', url: `${FeatureName.LEADS}/${id}/communication`}, {useCache: false});

  useEffect(() => {
    setLoading(isGetLeadLoading, `SpartanCommunicationField/${id}`);
  }, [isGetLeadLoading, id]);

  useEffect(() => {
    if (getLeadCommResponse) {
      setCommunication(getLeadCommResponse.data.results);
    }
  }, [getLeadCommResponse]);

  useEffect(() => {
    if (hasGetLeadCommError) {
      NotificationService.getInstance().sendNotification(
        hasGetLeadCommError?.response?.data?.message,
        NotificationType.ERROR
      );
    }
  }, [hasGetLeadCommError]);

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        overflowX: 'visible',
        maxHeight: 500,
        width: '100%',
      }}
    >
      {communication &&
        communication?.map((communicationItem: SpartanCommunicationItem, index: number) => (
          <Grid
            key={`lead_${id}_communication_item_${index}`}
            container
            spacing={3}
            alignItems="top"
            sx={{pt: (theme) => theme.spacing(2)}}
          >
            {communicationItem?.type === CommunicationType.CALL && (
              <CallCommunicationField communicationItem={communicationItem} />
            )}
            {communicationItem?.type === CommunicationType.SMS && (
              <SmsCommunicationField communicationItem={communicationItem} />
            )}
            {communicationItem?.type === CommunicationType.RVM && (
              <RvmCommunicationField communicationItem={communicationItem} />
            )}
            {communicationItem?.type === CommunicationType.AI_CALL && (
              <AICallCommunicationField communicationItem={communicationItem} />
            )}
            <Divider sx={{width: '100%', mt: (theme) => theme.spacing(1)}} />
          </Grid>
        ))}
      {!communication && <Typography variant="h6">{t('communication.no-communication-msg')}</Typography>}
    </Box>
  );
}
